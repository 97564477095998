import { useEffect } from "react";

const SocialLoginSuccess = () => {

    useEffect(() => {
        window.close()
    }, [])
    
}

export default SocialLoginSuccess;